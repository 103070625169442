export default interface WorkflowInstance {
  uid: string;
  workflow: string;
  status: string;
  metadata: any;
  state: WorkflowTaskProps[];
}

export interface WorkflowTaskProps {
  name: string;
  status: string;
  metadata: any;
}

/**
 * Class representing a task within a workflow
 */
export class WorkflowTask {
  props: Required<WorkflowTaskProps>;

  /**
   * @param props The task definition properties
   */
  constructor(props: WorkflowTaskProps) {
    this.props = {
      ...props,
    };
  }
}
